import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Footer from "../../components/footer"
import { StaticImage } from "gatsby-plugin-image"

export default class GasOil extends React.Component {

  render() {

    return (

      <Layout>

        <Seo title="SPCO" lang="en" />

        <section className="wow animate__fadeIn">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-lg-5 col-md-6 text-center md-margin-30px-bottom wow animate__fadeInLeft">
                <StaticImage
                  src="../../images/s5.jpg"
                  formats={["auto", "webp", "avif"]}
                  alt=""
                  className="border-radius-6 w-100 shadow"
                />
              </div>
              <div className="col-12 col-lg-7 col-md-6 text-center text-md-start padding-eight-lr lg-padding-six-lr md-padding-15px-lr wow animate__fadeInRight" data-wow-delay="0.2s">

                <span className="text-deep-pink alt-font margin-10px-bottom d-inline-block text-medium">Gas oil</span>

                <p>Gas oil or diesel fuel (French: Gazole) is used as fuel in diesel engines and thermal installations. Its range of hydrocarbon is between C14-C20 and even C25 with a boiling point range of 385-250 ͦ C. Gas oil mainly consists of three groups of paraffinic, naphthenic and aromatic with minimum flash point 54 ͦ C and maximum pour point of 0 °C. Its density is 15.6 ° C which equals to 820-860 kg / m3. Cetane number is the most important feature that it should be more than 50 so that engine can work easily and silently. </p>


              </div>
            </div>
          </div>
        </section>

        <Footer />

      </Layout>

    )

  }

}